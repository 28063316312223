@tailwind base;

@tailwind components;

@media print {
  body {
    font-size: 0.9rem;
  }
}

h1 {
  @apply text-4xl;
  @apply font-serif;
}

h2 {
  @apply text-xl;
  @apply text-gray-900;
  @apply opacity-75;
}

a {
  @apply underline;
  @apply text-blue-600;

  &:hover {
    @apply text-blue-800;
  }

  &:focus {
    outline: 1px dashed #63b3ed;
  }
}

header ul {
  li {
  }

  li:before {
    content: "/";
    @apply px-2;
    @apply opacity-50;
  }

  li:first-child:before {
    content: "";
  }

  @media print {
    li.print\:hidden:before {
      content: "";
    }
  }
}

dl {
  display: grid;
  grid-template-columns: 1fr min-content;

  dt {
    text-align: right;
    @apply whitespace-no-wrap;
  }
}

li {
  &.period {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-rows: min-content min-content min-content;
    grid-template-areas:
      "title date"
      "location location"
      "details details";

    span:nth-child(1) {
      grid-area: title;
      @apply font-semibold;
    }

    span:nth-child(2) {
      grid-area: date;
      text-align: right;
      @apply whitespace-no-wrap;
      @apply text-gray-800;
      @apply opacity-75;
    }

    span:nth-child(3) {
      grid-area: location;
      @apply opacity-75;
    }

    ul:nth-child(4) {
      grid-area: details;
      @apply mt-2;
    }
  }

  &.period + &.period {
    @apply mt-3;
  }
}

section + section {
  @apply mt-8;
}

p + p {
  @apply mt-2;
}

@tailwind utilities;
